import React, { useEffect, useState } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { motion, useViewportScroll } from 'framer-motion';
import Nav from '../molecules/Nav';
import { useMeta } from '../../utils/globals';

const Navbar = () => {
  const { name, headline } = useMeta();
  const { scrollY } = useViewportScroll();

  const navClassName = {
    start: 'text-white',
    scroll: 'text-primary-900',
  };

  const [showBg, setShowBg] = useState(false);
  const [navClasses, setNavClasses] = useState(navClassName.start);

  const update = () => {
    if (scrollY?.current < 1) {
      setNavClasses(navClassName.start);
      setShowBg(false);
    } else {
      setNavClasses(navClassName.scroll);
      setShowBg(true);
    }
  };

  useEffect(() => {
    update();
    scrollY.onChange(() => update());
  });

  const variants = {
    start: {
      height: 0,
    },
    scroll: {
      height: '100%',
      transition: {
        type: 'spring',
        duration: 0.8,
      },
    },
  };

  return (
    <nav
      id="siteNavbar"
      className={`fixed z-50 w-full text-white section-padding transition-all duration-500 ${navClasses}`}
    >
      <motion.div
        className="absolute top-0 right-0 bg-white w-full z-0"
        variants={variants}
        animate={showBg ? 'scroll' : 'start'}
      ></motion.div>
      <div className="relative container z-10 mx-auto flex justify-between items-center h-16 md:h-24 border-b border-white/50">
        <button
          onClick={() => scrollTo(`#hero`)}
          className="uppercase text-base"
        >
          <strong>{name}</strong>
          <span className="ml-2">{headline}</span>
        </button>
        <Nav />
      </div>
    </nav>
  );
};

export default Navbar;
