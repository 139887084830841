import React from 'react';
import config from '../../../site.config.json';

const Footer = ({ className, ...props }) => {
  const { company: c } = config;

  return (
    <footer className={`text-center p-6 text-xs ${className}`} {...props}>
      <div className="container mx-auto">
        <p>
          © {new Date().getFullYear()} {c.name} All rights reserved.{' '}
          <a className="underline" href="tel:+39037687067">
            Tel: +39 0376 87067
          </a>{' '}
          | P.IVA {c.vatname} | REA: {c.rea} | C.S.I.V. {c.asset}&euro;
        </p>
        <p className="mt-2 font-bold">
          Tutta la documentazione grafica e testuale è ad uso puramente
          illustrativo - non comporta vincolo contrattuale
        </p>
      </div>
    </footer>
  );
};

export default Footer;
