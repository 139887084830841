import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMdContent } from '../../utils/globals';
import Section from '../templates/Section';
import Title from '../atoms/Title';
import Content from '../atoms/Content';
import cover from '../../images/external/panorama.jpg';
import LetterMask from '../atoms/LetterMask';
import { motion } from 'framer-motion';
import { fadeRight } from '../../utils/animations';
import { bgImage } from '../../utils/styles';

const Energy = () => {
  const { h, html } = useMdContent(
    useStaticQuery(graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/energy.md/" }) {
          frontmatter {
            title
          }
          html
        }
      }
    `)
  );

  return (
    <Section
      id="energy"
      className="w-screen h-auto overflow-hidden text-white p-0 pb-0 lg:p-6"
    >
      <div className="container px-6 pb-12 md:px-0 relative md:grid md:grid-cols-12 mx-auto pt-2 md:py-0 items-center">
        <div
          className="absolute top-0 left-0 -lg:left-8 md:left-0 w-full lg:w-10/12 h-full -z-10 opacity-60"
          style={bgImage(cover)}
        >
          <div className="absolute top-0 left-0 md:left-0 w-full h-full bg-accent-700/50"></div>
        </div>

        <div className="md:col-start-2 md:col-span-6 mt-24 md:mb-24 lg:my-4">
          <Title className="text-5xl lg:text-8xl 3xl:text-9xl text-inherit">
            {h.title}
          </Title>
          <Content content={html} />
        </div>

        <motion.span
          variants={fadeRight}
          initial="hidden"
          whileInView="show"
          viewport={{ amount: 0.5 }}
          className="absolute -right-16 -top-20 -z-10 w-auto block text-center md:top-0 md:right-0 md:relative md:colspan-5"
        >
          <LetterMask letter="a" fontsize="70vw" bg={`url(${cover})`} />
        </motion.span>
      </div>
    </Section>
  );
};

export default Energy;
