import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../atoms/Title';
import ContactForm from '../molecules/ContactForm';
import Section from '../templates/Section';

const Contacts = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(fileAbsolutePath: { regex: "/contacts.md/" }) {
        frontmatter {
          title
          headline
        }
      }
    }
  `).markdownRemark;

  const { frontmatter: h } = data;

  return (
    <Section id="contacts">
      <div className="container mx-auto">
        <Title className="md:text-6xl lg:text-8xl">{h.title}</Title>

        <div className="bg-primary-50 pt-10 pb-16 md:pb-36">
          <div className="w-10/12 mx-auto">
            <p
              className="mb-24"
              dangerouslySetInnerHTML={{ __html: h.headline }}
            ></p>
            <ContactForm color="text" />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contacts;
