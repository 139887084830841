import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const data = useStaticQuery(graphql`
    {
      allMenuJson {
        edges {
          node {
            id
            label
            menuId
          }
        }
      }
    }
  `);

  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <button
        className={`nav-trigger ${menuOpen ? 'text-text' : 'text-inherit'}`}
        onClick={handleOpenMenu}
      >
        <HiOutlineMenuAlt4 size={24} />
      </button>
      <ul id="siteNav" className={`${menuOpen ? 'open' : ''}`}>
        {data.allMenuJson.edges.map(({ node }) => (
          <li key={node.id}>
            <button
              onClick={() => {
                scrollTo(`#${node.menuId}`);
                setMenuOpen(false);
              }}
            >
              {node.label}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Nav;
