import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { isEmail, isRequired, minLength } from '../../utils/form-validation';
import { RiMailSendLine, RiLoader4Fill } from 'react-icons/ri';
import config from '../../../site.config.json';
import Field from '../atoms/form/Field';
import Checkbox from '../atoms/form/Checkbox';

const ContactForm = ({ color = 'white' }) => {
  const form = config.forms.contacts;

  const initialValue = {
    name: '',
    email: '',
    phone: '',
    message: '',
    legalsAgree: false,
  };

  const [state, handleSubmit] = useForm(form.FS_ID);

  const [value, setValue] = useState(initialValue);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const validName = isRequired(value.name) && minLength(value.name);
    const validEmail = isRequired(value.email) && isEmail(value.email);
    const validPhone = isRequired(value.phone);
    const validMessage = isRequired(value.message);
    const validLegals = isRequired(value.legalsAgree);

    setIsValid(
      validName && validEmail && validPhone && validMessage && validLegals
    );
  }, [value]);

  const handleInputChange = (e) => {
    const { type } = e.target;
    const v = type === 'checkbox' ? e.target.checked : e.target.value;

    setValue({
      ...value,
      [e.target.name]: v,
    });
  };

  if (state.succeeded) {
    return (
      <div className="px-8 py-12 text-center">
        <span className="inline-block rounded-full border border-white p-8 mb-4">
          <RiMailSendLine className="text-4xl text-white" />
        </span>
        <h3 className="font-bold text-2xl uppercase">
          {config.messages.form_request_sent.title}
        </h3>
        <p>{config.messages.form_request_sent.message}</p>
      </div>
    );
  }

  return (
    <form
      id="contactForm"
      onSubmit={handleSubmit}
      className="grid grid-cols-1 gap-4"
    >
      {!!state.errors?.length && (
        <div className="font-sans text-red-600 bg-red-200 rounded p-4">
          <ValidationError errors={state.errors} />
        </div>
      )}
      {form.fields &&
        form.fields.map((f) => (
          <Field
            id={f.name}
            key={f.name}
            type={f.type}
            name={f.name}
            label={f.label}
            value={value[f.name]}
            required={f.required}
            disabled={state.submitting}
            color={color}
            onChange={handleInputChange}
          />
        ))}

      <footer className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 items-center">
        <Checkbox
          name="legalsAgree"
          checked={value.legalsAgree}
          disabled={state.submitting}
          onChange={handleInputChange}
        >
          ho preso visione della
          <a
            href="https://www.morbiocostruzioni.com/privacy-policy/"
            target="_BLANK"
            rel="noreferrer"
            className="underline ml-1"
          >
            Privacy Policy
          </a>
        </Checkbox>
        <button
          className={`flex items-center bg-primary-700 rounded py-4 px-10 text-white uppercase font-bold disabled:opacity-50 transition-all duration-500`}
          type="submit"
          disabled={!isValid || state.submitting}
        >
          <span className="mr-4 text-xl">
            {state.submitting ? (
              <RiLoader4Fill className="animate-spin" />
            ) : (
              <RiMailSendLine />
            )}
          </span>
          {state.submitting ? form.submit_loading_label : form.submit_label}
        </button>
      </footer>
    </form>
  );
};

export default ContactForm;
