import React from 'react';
import Navbar from '../components/organisms/Navbar';
import Hero from '../components/organisms/Hero';
import Intro from '../components/organisms/Intro';
import Location from '../components/organisms/Location';
import Technologies from '../components/organisms/Technologies';
import Park from '../components/organisms/Park';
import Services from '../components/organisms/Services';
import {
  AptIntro,
  AptDescription,
  AptDetails,
  Solutions,
} from '../components/organisms/Apartments';
import Contacts from '../components/organisms/Contacts';
import Brochure from '../components/organisms/Brochure';
import BrochureDownload from '../components/atoms/BrochureDownload';
import Countdown from '../components/Countdown';
import Seo from '../components/Seo';
import Energy from '../components/organisms/Energy';

const IndexPage = () => {
  const comingsoon = process.env.GATSBY_COMINGSOON_MODE === 'true';

  return (
    <>
      <Seo />

      {comingsoon ? (
        <Countdown />
      ) : (
        <>
          <Navbar />
          <Hero />
          <Location />
          <Energy />
          <Technologies />
          <Park />
          <Services />
          <AptIntro />
          <AptDescription />
          <AptDetails />
          <Solutions />
          <Intro />
          <Contacts />
          <BrochureDownload fab={true} />
          <Brochure />
        </>
      )}
    </>
  );
};

export default IndexPage;
