import React from 'react';
import { motion } from 'framer-motion';
import { fadeUp } from '../../utils/animations';

const Content = ({ content, className, ...props }) => {
  return (
    <motion.div
      variants={fadeUp}
      initial="hidden"
      whileInView="show"
      className={`content-wrapper ${className}`}
      {...props}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default Content;
