import React from 'react';
import loadable from '@loadable/component';
import Background from '../images/curtains.jpg';
import ContactForm from './molecules/ContactForm';
import Footer from './organisms/Footer';
import Cta from './atoms/Cta';
import scrollTo from 'gatsby-plugin-smoothscroll';
import IconArrowDown from '../icons/arrow-down.svg';
import { motion } from 'framer-motion';

const DateCountdown = loadable(() => import('react-date-countdown-timer'));

const Countdown = () => {
  const deadline = 'April 18, 2022 00:00:00 GMT+01:00';
  const locales = ['anno', 'mese', 'giorno', 'ora', 'minuto', 'secondo'];
  const localesPlural = ['anni', 'mesi', 'giorni', 'ore', 'minuti', 'secondi'];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 30 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
      },
    },
  };

  return (
    <main
      className="font-accent text-white relative"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute top-0 right-0 w-full h-full z-0 bg-gradient-to-tr from-primary-900 mix-blend-multiply opacity-70"></div>
      <section className="relative z-10 py-16 flex h-screen items-center justify-center uppercase text-center">
        <motion.div variants={container} initial="hidden" animate="show">
          <motion.p
            variants={item}
            style={{ letterSpacing: '.5rem' }}
            className="block"
          >
            Scopri
          </motion.p>

          <h1 className="my-8" style={{ letterSpacing: '1.5rem' }}>
            <motion.span
              variants={item}
              className="block text-sm md:font-bold md:text-xl lg:text-2xl"
            >
              Residence
            </motion.span>
            <motion.span
              variants={item}
              className="block font-bold text-3xl md:font-normal md:text-5xl lg:text-7xl mt-4"
            >
              LE LOGGE
            </motion.span>
          </h1>

          <motion.span
            variants={item}
            style={{ letterSpacing: '.5rem' }}
            className="block text-sm md:font-bold"
          >
            borgochiesanuova
          </motion.span>

          <motion.div variants={item}>
            <DateCountdown
              dateTo={deadline}
              locales={locales}
              locales_plural={localesPlural}
            />
          </motion.div>

          <motion.div variants={item}>
            <Cta
              label="Maggiori informazioni"
              className="mx-auto mt-24"
              onClick={() => scrollTo('#comingsoonContacts')}
              Icon={IconArrowDown}
            />
          </motion.div>
        </motion.div>
      </section>
      <motion.section
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ amount: 0.5 }}
        id="comingsoonContacts"
        className="relative z-10 md:flex md:items-center md:justify-between md:flex-col md:h-screen"
      >
        <motion.div className="grow w-full flex items-center" variants={item}>
          <div className=" md:w-8/12 lg:w-5/12 mx-auto">
            <h2 className="text-3xl md:text-4xl mb-8 uppercase text-center">
              Contattaci
            </h2>
            <ContactForm />
          </div>
        </motion.div>

        <motion.div variants={item}>
          <Footer />
        </motion.div>
      </motion.section>
    </main>
  );
};

export default Countdown;
