import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { GrClose, GrAdd } from 'react-icons/gr';

const Modal = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('locked-body');
    } else {
      document.body.classList.remove('locked-body');
    }

    const escape = (e) => {
      if (e.key === 'Escape') {
        close();
      }
    };

    window.addEventListener('keydown', escape);

    return () => window.removeEventListener('keydown', escape);
  });

  const open = () => !isOpen && setIsOpen(true);
  const close = () => isOpen && setIsOpen(false);

  const presenceTransition = {
    duration: 0.2,
    ease: 'easeInOut',
    delayChildren: 0.3,
  };

  const container = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: presenceTransition,
    },
    exit: {
      opacity: 0,
      transition: presenceTransition,
    },
  };

  const dialog = {
    initial: { y: 100, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <>
      <button onClick={open} className="flex mt-12 items-center">
        <span className="mr-4">
          <i className="w-12 h-12 md:w-16 md:h-16 border border-text rounded-full flex items-center justify-center">
            <GrAdd size={18} />
          </i>
        </span>
        <span className="flex flex-col items-start text-left leading-6">
          {props.cta?.tag && (
            <span className={`text-sm uppercase text-${props.color}`}>
              {props.cta.tag}
            </span>
          )}
          <span className="font-bold">{props.cta.label}</span>
        </span>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={container}
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed z-50 w-full h-screen top-0 left-0 bg-primary-900/60 overflow-y-scroll md:px-12"
            onClick={close}
          >
            <motion.div
              variants={dialog}
              className="max-w-6xl mx-auto bg-white p-8 md:p-12 mt-32"
            >
              <header className="flex items-start justify-between space-x-10">
                <h3 className="font-bold text-lg md:text-2xl">{props.title}</h3>
                <button onClick={close}>
                  <GrClose size={24} />
                </button>
              </header>

              <main className="py-8 md:py-10"></main>
              {props.content ? (
                <div
                  dangerouslySetInnerHTML={{ __html: props.content }}
                  className="prose prose-neutral mx-auto prose-h4:text-2xl max-w-full container--text"
                ></div>
              ) : (
                props.children
              )}

              {props.showFooter && (
                <footer className="text-center mt-12">
                  <button onClick={close} className="uppercase font-bold">
                    chiudi
                  </button>
                </footer>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Modal;
