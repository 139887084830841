import React, { useState } from 'react';
import Section from '../../templates/Section';
import apartments from '../../../data/apartments/apartments.json';
import floorings from '../../../data/apartments/floorings.json';
import Title from '../../atoms/Title';
import Bilocale from '../../../images/plants/bilocale.png';
import Trilocale from '../../../images/plants/trilocale.png';
import Quadrilocale from '../../../images/plants/quadrilocale.png';
import Attico from '../../../images/plants/attico.png';
import Icon from '../../atoms/Icon';
import Concrete from '../../../images/floors/concrete.jpg';
import Stone from '../../../images/floors/stone.jpg';
import Wood from '../../../images/floors/wood.jpg';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import Cta from '../../atoms/Cta';
import IconDownload from '../../../icons/data-download.svg';
import { StaticImage } from 'gatsby-plugin-image';

const Solutions = () => {
  const plants = [Bilocale, Trilocale, Quadrilocale, Attico];

  const floors = {
    concrete: Concrete,
    stone: Stone,
    wood: Wood,
  };

  const [currentTab, setTab] = useState('2');

  const handleSetTab = (id) => {
    setTab(id);
  };

  const presenceTransition = {
    duration: 0.2,
    ease: 'easeInOut',
    delayChildren: 0.3,
  };

  const variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: presenceTransition,
    },
    exit: {
      opacity: 0,
      transition: presenceTransition,
    },
  };

  return (
    <Section id="solutions">
      <div className="container mx-auto">
        <header className="xl:grid grid-cols-2">
          <Title className="md:text-4xl lg:text-5xl xl:text-3xl max-w-xl xl:max-w-md text-primary-500">
            Scegli l'appartamento su misura per te
          </Title>

          {/* Tab nav */}
          <nav className="grid grid-cols-2 gap-4 md:flex md:items-center justify-center md:space-x-6 xl:space-x-12 py-6 md:py-0">
            {apartments.map((apt) => (
              <button
                onClick={() => handleSetTab(apt.prefix)}
                key={apt.prefix}
                className={`relative py-3 px-4 rounded ${
                  apt.prefix === currentTab ? 'border border-primary-400' : ''
                }`}
              >
                <span className="font-bold text-5xl absolute top-1/2 left-2 -translate-y-1/2 -z-10 text-primary-100">
                  {apt.prefix}
                </span>
                <span className="inline-block capitalize">{apt.type}</span>
              </button>
            ))}
          </nav>
        </header>

        {/* Tab panel */}
        {apartments.map((apt, i) => (
          <AnimatePresence key={apt.type}>
            {currentTab === apt.prefix && (
              <motion.div
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
                key={apt.type}
                className="md:grid grid-cols-2 gap-8 mt-8 md:mt-16 xl:mt-0"
              >
                <div className="max-w-xl">
                  <h3 className="capitalize font-bold text-4xl lg:text-6xl text-primary-700">
                    {apt.type}
                  </h3>

                  <div className="flex lg:space-x-12 mt-12">
                    <span className="py-4 pr-8 lg:pr-0">
                      <span className="text-3xl mr-1 lg:mr-2">{apt.size}</span>{' '}
                      <span className="text-xs lg:text-base">mq</span>
                    </span>
                    <span className="py-4 pl-4 lg:pl-8 border-l border-gray-200">
                      <span className="text-xs lg:text-base">Loggia</span>
                      <span className="text-3xl ml-2 lg:ml-4 mr-1 lg:mr-2">
                        {apt.lodgeSize}
                      </span>{' '}
                      <span className="text-xs lg:text-base">mq</span>
                    </span>
                  </div>

                  <p
                    className="py-8"
                    dangerouslySetInnerHTML={{ __html: apt.description }}
                  ></p>

                  <div className="grid grid-cols-2 border-t border-gray-200">
                    {apt.details.map((detail, i) => (
                      <div
                        className="flex justify-between py-6 even:pl-8 odd:pr-8 even:border-l even:border-gray-200"
                        key={i}
                      >
                        <span className="flex items-center space-x-2">
                          <Icon
                            collection="apartments"
                            name={detail.icon}
                            size={24}
                          />{' '}
                          <span className="hidden lg:inline-block">
                            {detail.key}
                          </span>
                        </span>
                        <span className="font-bold md:text-2xl">
                          {detail.value}
                        </span>
                      </div>
                    ))}

                    {Object.keys(apt.floorings).map((floor) => (
                      <div
                        key={floor}
                        className="py-6 odd:pr-8 border-t even:pl-8 even:border-l border-gray-200"
                      >
                        <strong className="text-xs lg:text-base">
                          {
                            floorings.locations.find((f) => f.uid === floor)
                              .name
                          }
                        </strong>
                        <div className="grid grid-cols-1 gap-4 mt-4">
                          {apt.floorings[floor].map((trt) => (
                            <div
                              key={trt}
                              className="flex flex-row items-center"
                            >
                              <img
                                className="w-8 rounded-full"
                                src={floors[trt]}
                                alt={trt}
                              />
                              <span className="block text-xs ml-4">
                                {
                                  floorings.floors.find((f) => f.uid === trt)
                                    .name
                                }
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <figure className="mt-8 lg:mt-0 text-center">
                  <img src={plants[i]} alt={apt.type} />

                  <Cta
                    Icon={IconDownload}
                    size="lg"
                    extLink={`https://leloggemantova.it/download/lelogge-mantova-${apt.type}.pdf`}
                    color="text"
                    download="brochure LE LOGGE"
                    label="scarica pdf"
                  />
                </figure>
              </motion.div>
            )}
          </AnimatePresence>
        ))}

        <StaticImage
          src="../../../images/internal/bedroom.jpg"
          alt="bedroom"
          className="mt-32 md:mt-48"
          formats={['auto']}
        />
      </div>
    </Section>
  );
};

export default Solutions;
