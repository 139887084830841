import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeUp } from '../../utils/animations';

const CtaEl = ({ extLink, children, ...props }) => {
  return extLink ? (
    <motion.a
      variants={fadeUp}
      initial="hidden"
      whileInView="show"
      href={extLink}
      target="_BLANK"
      rel="noreferrer"
      download
      {...props}
    >
      {children}
    </motion.a>
  ) : (
    <motion.button
      variants={fadeUp}
      initial="hidden"
      whileInView="show"
      type="button"
      {...props}
    >
      {children}
    </motion.button>
  );
};

const Cta = ({
  label,
  extLink,
  size,
  Icon,
  className,
  color,
  labelOnHover,
  ...props
}) => {
  let sizeClassName, iconElSizeClassName, iconSizeClassName;

  const textColor = color ? `text-${color}` : 'white';

  switch (size) {
    case 'lg':
      sizeClassName = 'space-x-5';
      iconElSizeClassName = 'w-12 h-12 md:w-20 md:h-20 border';
      break;
    case 'sm':
      sizeClassName = 'space-x-2';
      iconElSizeClassName = 'w-10 h-10 border-2';
      iconSizeClassName = 'h-4 w-auto';
      break;
    default:
      sizeClassName = 'space-x-5';
      iconElSizeClassName = 'w-12 h-12 md:w-16 md:h-16 border';
      break;
  }

  const [showLabel, setShowLabel] = useState(!labelOnHover);

  const handleShowLabelOnHover = (show) => {
    if (labelOnHover) {
      setShowLabel(show);
    }
  };

  return (
    <CtaEl
      layout
      onMouseEnter={() => handleShowLabelOnHover(true)}
      onMouseLeave={() => handleShowLabelOnHover(false)}
      extLink={extLink}
      className={`inline-flex items-center font-bold cursor-pointer py-4 ${textColor} ${sizeClassName} ${className}`}
      {...props}
    >
      {Icon && (
        <motion.i
          layout
          className={`flex items-center justify-center border-current rounded-full ${iconElSizeClassName}`}
        >
          <Icon className={iconSizeClassName} />
        </motion.i>
      )}
      <AnimatePresence>
        {showLabel && (
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.4 } }}
            exit={{ opacity: 0, y: 20 }}
            layout
            className="cta__label"
          >
            {label}
          </motion.span>
        )}
      </AnimatePresence>
    </CtaEl>
  );
};

export default Cta;
