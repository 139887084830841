import React from 'react';
import { motion } from 'framer-motion';
import { fadeUp } from '../../utils/animations';

const Title = ({ children, className, ...props }) => {
  return (
    <motion.h2
      variants={fadeUp}
      initial="hidden"
      whileInView="show"
      className={`font-bold text-primary-700 text-4xl md:text-5xl mb-8 md:mb-12 ${className}`}
      {...props}
    >
      {children}
    </motion.h2>
  );
};

export default Title;
