import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  offscreen: {
    opacity: 0,
    y: 100,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const viewport = { once: false, amount: 1 };

const Section = ({ id, children, ...props }) => {
  return (
    <section
      id={id}
      {...props}
      className={`relative section-padding py-16 md:py-28 ${props.className}`}
    >
      {children ? (
        children
      ) : (
        <motion.h2
          className="text-2xl lg:text-6xl text-center font-bold py-6"
          variants={variants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={viewport}
        >
          {id}
        </motion.h2>
      )}
    </section>
  );
};

export default Section;
