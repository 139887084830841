import React from 'react';
import Section from '../../templates/Section';
import { motion } from 'framer-motion';
import { fadeLeft, fadeRight } from '../../../utils/animations';
import section from '../../../data/apartments/apartments-descriptions.json';
import Headline from '../../atoms/Headline';
import Terrace2 from '../../../images/internal/terrace2.jpg';
import Terrace from '../../../images/internal/terrace.jpg';

const AptDescription = () => {
  const images = [Terrace2, Terrace];

  return (
    <Section
      id="apartmentsDescription"
      className="p-0 overflow-x-hidden md:pt-0"
    >
      <div className="flex flex-col space-y-16 mt-16">
        {section.content.map(({ title, description }, i) => (
          <section
            key={i}
            className="flex flex-col lg:flex-row items-center lg:even:flex-row-reverse"
          >
            <motion.div
              variants={i % 2 === 0 ? fadeLeft : fadeRight}
              initial="hidden"
              whileInView="show"
              className="p-8 lg:p-16 xl:p-24 xl:w-10/12"
            >
              <Headline className="mb-8">{title}</Headline>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </motion.div>

            <motion.div
              variants={i % 2 === 0 ? fadeRight : fadeLeft}
              initial="hidden"
              whileInView="show"
            >
              <img src={images[i]} alt={title} className="px-8 lg:px-0" />
            </motion.div>
          </section>
        ))}
      </div>
    </Section>
  );
};

export default AptDescription;
