export const bgImage = (imageUrl, gradient = null) => {
  const g = {
    from: 'rgba(0,0,0, 0.75)',
    to: 'transparent',
    direction: 'top',
    ...gradient,
  };

  const bg = gradient
    ? `linear-gradient(to ${g.direction}, ${g.from}, ${g.to}), url(${imageUrl})`
    : `url(${imageUrl})`;

  return {
    backgroundImage: bg,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  };
};
