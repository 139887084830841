import React from 'react';
import Section from '../templates/Section';
import Footer from '../../components/organisms/Footer';
import Title from '../atoms/Title';
import BrochureDownload from '../atoms/BrochureDownload';
import Background from '../../images/external/sky_view.jpg';
import { bgImage } from '../../utils/styles';

const Brochure = () => {
  return (
    <Section
      id="brochure"
      className="text-white pt-36 pb-0 md:pb-0 flex flex-col xl:h-screen items-end justify-end"
      style={bgImage(Background, { direction: 'top left' })}
    >
      <div className="container flex flex-col items-end mx-auto text-center md:text-right">
        <Title className=" text-2xl lg:text-7xl text-inherit mb-0 max-w-xl">
          Vuoi altre informazioni?
        </Title>
        <div className="text-xl md:text-2xl text-center md:text-right">
          <BrochureDownload />
        </div>
      </div>

      <Footer className="mt-32 w-full text-white" />
    </Section>
  );
};

export default Brochure;
