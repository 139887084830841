import React from 'react';
import Title from '../atoms/Title';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '../templates/Section';
import { useMdContent } from '../../utils/globals';
import Content from '../atoms/Content';
import Cta from '../atoms/Cta';
import IconArrowRight from '../../icons/arrow-right.svg';
import SketchImg from '../../images/sketch.jpg';
import Morbio from '../../images/morbio.svg';

const Intro = () => {
  const { h, html } = useMdContent(
    useStaticQuery(graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/morbio-intro.md/" }) {
          frontmatter {
            title
            cta_label
          }
          html
        }
      }
    `)
  );

  return (
    <Section id="presentation" className="lg:py-48 mt-12">
      <img
        src={SketchImg}
        alt="Morbio sketch"
        title="Morbio sketch"
        className="absolute top-0 -left-12 object-fill -z-10 opacity-10"
      />
      <div className="container mx-auto grid md:grid-cols-2 gap-12 md:gap-8 items-center">
        <Morbio className="mx-auto w-6/12 md:w-10/12 lg:w-6/12 h-auto" />
        <div>
          <Title>{h.title}</Title>
          <Content content={html} className="uppercase" />
          <Cta
            label={h.cta_label}
            size="sm"
            color="primary-700"
            className="uppercase mt-10"
            Icon={IconArrowRight}
            extLink="https://www.morbiocostruzioni.com/"
          />
        </div>
      </div>
    </Section>
  );
};

export default Intro;
