import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMdContent } from '../../utils/globals';
import Section from '../templates/Section';
import technologies from '../../data/technologies/technologies.json';
import Title from '../atoms/Title';
import Content from '../atoms/Content';
import Icon from '../atoms/Icon';
import Modal from '../molecules/Modal';
import { AnimatePresence, motion } from 'framer-motion';
import Background from '../../images/internal/bedroom_blurred.png';
import { bgImage } from '../../utils/styles';

const Technologies = () => {
  const { h, html } = useMdContent(
    useStaticQuery(graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/technologies.md/" }) {
          frontmatter {
            title
            modal_cta_tag
            modal_cta_label
            modal_title
          }
          html
        }
      }
    `)
  );

  const { ready, provisioned, categories } = technologies;

  const [category, setCategory] = useState('energy');

  return (
    <Section
      id="technologies"
      className="pb-36 lg:py-48 xl:py-64"
      style={bgImage(Background, {
        from: 'rgba(255,255,255,1)',
        to: 'rgba(255,255,255, 0.4)',
        direction: 'left',
      })}
    >
      <div className="container mx-auto xl:grid grid-cols-2 gap-12">
        <div className="mt-24 lg:mt-0 md:px-12 lg:px-24">
          <Title className="xl:text-7xl 2xl:text-8xl text-primary-700">
            {h.title}
          </Title>
          <Content content={html} className="" />

          <Modal
            cta={{
              label: h.modal_cta_label,
              tag: h.modal_cta_tag,
            }}
            title={h.modal_title}
            color="primary-500"
            showFooter={true}
          >
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-12 py-16">
              {provisioned.map((p) => (
                <div className="text-center" key={p.uid}>
                  <span className="text-priamry-700 bg-primary-50 rounded-full w-24 h-24 inline-flex items-center justify-center mb-6">
                    <Icon collection="energy" name={p.icon} size={48} />
                  </span>
                  <h4>{p.title}</h4>
                </div>
              ))}
            </div>
          </Modal>
        </div>

        <div className="flex flex-col items-center justify-center relative lg:order-first">
          <nav className="flex space-x-2 md:space-x-4 xl:absolute top-0 xl:left-1/2 xl:-translate-x-1/2 mt-16 xl:mt-0">
            {categories.map((cat) => (
              <button
                key={cat.uid}
                onClick={() => setCategory(cat.uid)}
                className={`technology-group ${
                  cat.uid === category ? 'active' : ''
                }`}
              >
                <span className="hidden md:block">
                  <Icon collection="energy" name={cat.icon} size={36} />
                </span>
                <span>{cat.name}</span>
              </button>
            ))}
          </nav>

          <AnimatePresence>
            <div className="w-full md:flex flex-wrap justify-center mt-24 md:pt-12 xl:mt-0">
              {ready
                .filter((r) => r.category === category)
                .map((t, i) => (
                  <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.7, delay: 0.2 * i },
                    }}
                    className="p-8 md:p-0 md:m-8 2xl:m-10 flex md:w-1/3 grow-0 items-center justify-start md:block md:text-center"
                    key={t.uid}
                  >
                    <span>
                      <span className="bg-primary-800 text-white w-20 h-20 xl:w-24 xl:h-24 inline-flex items-center justify-center rounded-full">
                        <Icon collection="energy" name={t.icon} size={32} />
                      </span>
                    </span>
                    <h5 className="leading-6 ml-6 md:mt-4 md:ml-0 uppercase text-sm 2xl:text-base">
                      {t.title}
                    </h5>
                  </motion.div>
                ))}
            </div>
          </AnimatePresence>
        </div>
      </div>
    </Section>
  );
};

export default Technologies;
