import React from 'react';
import Section from '../templates/Section';
import Title from '../atoms/Title';
import services from '../../data/services/services.json';
import { motion } from 'framer-motion';
import { fadeUp } from '../../utils/animations';
import { StaticImage } from 'gatsby-plugin-image';
import Icon from '../atoms/Icon';

const ServicesCategory = ({ services, category, ...props }) => {
  const filteredServices = services.filter((s) => s.category === category.name);

  return (
    <div {...props}>
      {filteredServices.map((service) => (
        <span key={service.title} className="flex items-center my-8">
          <span>
            <span className="border w-16 h-16 flex items-center justify-center border-white rounded-full">
              <Icon collection="services" name={service.icon} size={28} />
            </span>
          </span>
          <span className="ml-4 font-bold uppercase">{service.title}</span>
        </span>
      ))}

      <p
        className="mt-20"
        dangerouslySetInnerHTML={{ __html: category.description }}
      />
    </div>
  );
};

const Services = () => {
  const { data, categories } = services;

  const getCategory = (name) => categories.find((cat) => cat.name === name);

  return (
    <Section id="services" className="p-0 py-0 md:py-0 xl:pt-0 text-white">
      <motion.section
        variants={fadeUp}
        initial="hidden"
        whileInView="show"
        className="bg-primary-600 flex flex-col xl:flex-row"
      >
        <div className="xl:w-5/12 p-12 md:p-24">
          <Title className="text-inherit">{services.title}</Title>
          <ServicesCategory services={data} category={getCategory('comfort')} />
        </div>
        <StaticImage
          className="xl:w-7/12"
          src="../../images/external/panorama_street.jpg"
          alt={getCategory('comfort').name}
          objectPosition="left"
          formats={['auto']}
        />
      </motion.section>

      <motion.section
        variants={fadeUp}
        initial="hidden"
        whileInView="show"
        className="bg-primary-700 flex flex-col xl:flex-row-reverse"
      >
        <ServicesCategory
          className="xl:w-5/12 p-12 md:p-24"
          services={data}
          category={getCategory('fitness')}
        />
        <StaticImage
          className="xl:w-7/12"
          src="../../images/internal/gym.jpg"
          alt={getCategory('fitness').name}
          formats={['auto']}
        />
      </motion.section>

      <motion.section
        variants={fadeUp}
        initial="hidden"
        whileInView="show"
        className="bg-primary-800 flex flex-col xl:flex-row"
      >
        <ServicesCategory
          className="xl:w-5/12 p-12 md:p-24"
          services={data}
          category={getCategory('sharing')}
        />
        <div className="xl:w-7/12 grid grid-cols-2">
          <StaticImage
            src="../../images/party.jpg"
            alt={getCategory('comfort').name}
            formats={['auto']}
          />
          <StaticImage
            src="../../images/desk.jpg"
            alt={getCategory('comfort').name}
            formats={['auto']}
          />
        </div>
      </motion.section>
    </Section>
  );
};

export default Services;
