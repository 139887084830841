import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../images/favicon.png';

const Seo = () => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const seo = site.siteMetadata;
  const metaTitle = `${seo.title} | ${seo.siteName}`;

  const links = {
    icon: favicon,
    canonical: seo.url,
  };

  const metaNames = {
    description: seo.description,
    robots: seo.robots,
    'twitter:card': seo.twitterCard,
    'twitter:title': metaTitle,
    'twitter:description': seo.description,
    'twitter:image': '',
    'twitter:image:width': '',
    'twitter:image:height': '',
    'twitter:image:alt': '',
  };

  const metaProps = {
    'og:locale': seo.locale,
    'og:type': seo.type,
    'og:title': metaTitle,
    'og:description': seo.description,
    'og:url': `${seo.url}${pathname}`,
    'og:site_name': seo.siteName,
    'og:image': seo.image,
    'og:image:width': '',
    'og:image:height': '',
    'og:image:alt': '',
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      meta={[
        ...Object.entries(metaNames).map(([k, v]) => ({
          name: k,
          content: v,
        })),
        ...Object.entries(metaProps).map(([k, v]) => ({
          property: k,
          content: v,
        })),
      ]}
      link={Object.entries(links).map(([k, v]) => ({ rel: k, href: v }))}
    />
  );
};

export default Seo;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        titleTemplate
        url: siteUrl
        description
        image
        locale
        type
        siteName
        twitterCard
        robots
      }
    }
  }
`;
