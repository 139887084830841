import React from 'react';

const LetterMask = ({ letter, bg, fontsize = '70rem' }, props) => {
  const style = {
    backgroundImage: `${bg}`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    fontSize: fontsize,
  };

  return (
    <span className="letter-mask" {...props} style={style}>
      {letter}
    </span>
  );
};

export default LetterMask;
