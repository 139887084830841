import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getDocById, useMdCollection } from '../../utils/globals';
import Title from '../atoms/Title';
import Headline from '../atoms/Headline';
import Section from '../templates/Section';
import Content from '../atoms/Content';
import Mantova from '../../images/mantova.jpg';
import Map from '../../images/map.png';
import { motion } from 'framer-motion';
import { fadeUp } from '../../utils/animations';
import PointOfInterests from '../molecules/PointOfInterests';
import Modal from '../molecules/Modal';

const Location = () => {
  const documents = useMdCollection(
    useStaticQuery(graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/location/" } }
        ) {
          edges {
            node {
              frontmatter {
                id
                title
                headline
                modal_cta_tag
                modal_cta_label
              }
              html
            }
          }
        }
      }
    `)
  );

  const content = getDocById(documents, 'content');
  const modal = getDocById(documents, 'modal');

  return (
    <Section id="location" className="px-0 xl:mt-48 overflow-x-hidden">
      <div className="container mx-auto section-padding">
        <Title className="text-center lg:text-8xl">{content.h.title}</Title>
        <div className="xl:w-10/12 mx-auto md:mt-20 md:flex lg:grid grid-cols-2 gap-8 md:mb-16 lg:mb-0">
          <Headline className="md:text-right uppercase">
            {content.h.headline}
          </Headline>
          <Content content={content.html} />
        </div>
      </div>
      <img
        src={Mantova}
        alt="Mantova"
        title="Mantova"
        className="relative w-full md:-mt-32 -z-10"
      />

      <div className="section-padding">
        <div className="container mx-auto grid lg:grid-cols-2 gap-8 -mt-48">
          <motion.div variants={fadeUp} initial="hidden" whileInView="show">
            <figure className="bg-primary-100 aspect-square lg:-translate-y-16">
              <img src={Map} alt="Mappa" title="Mappa" />
            </figure>
          </motion.div>

          <section className="md:pt-24 md:px-12">
            <motion.h4
              variants={fadeUp}
              initial="hidden"
              whileInView="show"
              className="uppercase"
            >
              Peculiarità della zona
            </motion.h4>

            <motion.h3
              variants={fadeUp}
              initial="hidden"
              whileInView="show"
              className="heading text-xl md:text-2xl mt-2"
            >
              Raggiungi facilmente decine di esercizi nei dintorni
            </motion.h3>

            <PointOfInterests />

            <Modal
              cta={{
                label: content.h.modal_cta_label,
                tag: content.h.modal_cta_tag,
              }}
              title={modal.h.title}
              color="primary-500"
              showFooter={true}
              content={modal.html}
            />
          </section>
        </div>
      </div>
    </Section>
  );
};

export default Location;
