import React from 'react';

const Checkbox = ({ label, color, children, ...props }) => {
  return (
    <label className="flex items-center flex-grow cursor-pointer pr-8">
      <input type="checkbox" className="hidden" {...props} />
      <span>
        <span
          className={`relative block w-6 h-6 border border-primary-700 rounded-full`}
        >
          <span
            className={`absolute w-3 h-3 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition bg-primary-700 rounded-full ${
              props.checked ? 'scale-1' : 'scale-0'
            }`}
          ></span>
        </span>
      </span>

      <span className="block text-sm leading-relaxed pl-2">
        {label ?? children}
      </span>
    </label>
  );
};

export default Checkbox;
