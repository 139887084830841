export const isRequired = (value) => !!value;

export const isEmail = (value) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(value);
};

export const minLength = (value, min = 3) => {
  return value.replace(/ /g, '').length >= min;
};

export const maxLength = (value, max = 100) => {
  return value.replace(/ /g, '').length <= max;
};
