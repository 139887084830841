import React from 'react';
import { motion } from 'framer-motion';
import { fadeRight } from '../../utils/animations';

const Headline = ({ children, className, ...props }) => {
  return (
    <motion.h3
      variants={fadeRight}
      initial="hidden"
      whileInView="show"
      className={`font-bold text-primary-700 text-xl ${className}`}
      {...props}
    >
      {children}
    </motion.h3>
  );
};

export default Headline;
