import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Cta from '../atoms/Cta';
import Background from '../../images/external/panorama_reverse.jpg';
import IconArrowDown from '../../icons/arrow-down.svg';
import { motion } from 'framer-motion';
import { fadeIn, fadeInChildren, fadeInY } from '../../utils/animations';
import { bgImage } from '../../utils/styles';

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(fileAbsolutePath: { regex: "/hero.md/" }) {
        frontmatter {
          title
          headline
          cta_label
        }
      }
    }
  `).markdownRemark;

  const { frontmatter: h } = data;

  const style = {
    writingMode: 'vertical-rl',
  };

  return (
    <header
      id="hero"
      className="relative z-0 h-screen section-padding text-white flex items-center justify-center"
      style={bgImage(Background, {
        direction: 'top right',
      })}
    >
      <div className="container relative grid grid-cols-12 mt-20 portrait:mt-20 md:mt-24 lg:mt-0 xl:mt-20">
        <motion.div
          variants={fadeInChildren}
          initial="hidden"
          whileInView="show"
          className="col-span-10 lg:col-span-10 xl:col-span-6 lg:col-start-2 2xl:col-start-2"
        >
          <motion.h3 variants={fadeIn} className="mb-2 uppercase">
            {h.tag}
          </motion.h3>

          <motion.h1
            variants={fadeIn}
            className="font-bold text-4xl md:text-6xl lg:text-8xl xl:text-6xl 2xl:text-7xl"
          >
            {h.title}
          </motion.h1>

          <motion.h2
            variants={fadeIn}
            className="lg:text-2xl mt-6 leading-7 lg:leading-10"
            dangerouslySetInnerHTML={{ __html: h.headline }}
          />

          <Cta
            label={h.cta_label}
            onClick={() => scrollTo('#location')}
            Icon={IconArrowDown}
            className="mt-12 portrait:mt-12 md:mt-0 lg:mt-12"
          />
        </motion.div>

        <motion.span
          variants={fadeInY}
          initial="hidden"
          animate="show"
          className="hidden sm:block absolute right-0 top-1/2 -translate-y-1/2 -rotate-180 uppercase"
          style={style}
        >
          {h.location}
        </motion.span>
      </div>
    </header>
  );
};

export default Hero;
