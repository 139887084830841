import React from 'react';
import { motion } from 'framer-motion';
import { fadeRight } from '../../utils/animations';

const Titletag = ({ children, ...props }) => {
  return (
    <motion.h3
      variants={fadeRight}
      initial="hidden"
      whileInView="show"
      {...props}
    >
      {children}
    </motion.h3>
  );
};

export default Titletag;
