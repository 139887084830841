import React, { useEffect, useState } from 'react';
import Cta from './Cta';
import IconDownload from '../../icons/data-download.svg';
import { useViewportScroll } from 'framer-motion';

const BrochureDownload = ({ fab = false }) => {
  const { scrollY } = useViewportScroll();
  const [scrolled, setScrolled] = useState(false);

  const classes = fab ? 'brochure-download' : 'mt-10';

  const update = () => {
    if (scrollY?.current < 100) {
      setScrolled(false);
    } else {
      setScrolled(true);
    }
  };

  useEffect(() => {
    if (fab) {
      update();
      scrollY.onChange(() => update());
    }
  });

  return (
    <Cta
      Icon={IconDownload}
      size="lg"
      className={`${classes} ${scrolled ? 'scrolled' : ''}`}
      extLink="https://leloggemantova.it/download/lelogge-mantova-brochure.pdf"
      color={'white'}
      labelOnHover={fab}
      download="brochure LE LOGGE"
      label="Scarica la brochure"
    />
  );
};

export default BrochureDownload;
