import { useStaticQuery, graphql } from 'gatsby';

export const useMeta = () => {
  const meta = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          name
          headline
          email
        }
      }
    }
  `);

  return meta.site.siteMetadata;
};

export const useMdContent = (staticQuery) => {
  const data = staticQuery;

  return {
    h: data.markdownRemark.frontmatter,
    html: data.markdownRemark.html,
  };
};

export const useMdCollection = (staticQuery) => {
  const data = staticQuery;

  return data.allMarkdownRemark.edges.map((e) => ({
    h: e.node.frontmatter,
    html: e.node.html,
  }));
};

export const getDocById = (documents, id) =>
  documents.find((d) => d.h.id === id);
