import React from 'react';
import Icon from '../atoms/Icon';
import pointsOfInterest from '../../data/poi.json';
import { motion } from 'framer-motion';
import { fadeUp } from '../../utils/animations';

const PointOfInterests = () => {
  return (
    <motion.ul
      variants={fadeUp}
      initial="hidden"
      whileInView="show"
      className="grid grid-cols-2 gap-x-4 gap-y-14 mt-16"
    >
      {pointsOfInterest.map((poi, i) => (
        <li
          key={i}
          className="flex flex-col md:flex-row items-center text-primary-500 text-center md:text-left"
        >
          <Icon collection="poi" name={poi.icon} />
          <div className="mt-4 md:ml-4 md:mt-0">
            <h5 className="font-bold text-sm uppercase mb-0">{poi.title}</h5>
            <p className="text-sm text-text">{poi.description}</p>
          </div>
        </li>
      ))}
    </motion.ul>
  );
};

export default PointOfInterests;
