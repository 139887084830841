import React from 'react';
import Section from '../../templates/Section';
import Background from '../../../images/internal/terrace_parquet.jpg';
import { bgImage } from '../../../utils/styles';
import Title from '../../atoms/Title';
import { useMdContent } from '../../../utils/globals';
import { graphql, useStaticQuery } from 'gatsby';
import Content from '../../atoms/Content';

const AptIntro = () => {
  const { h, html } = useMdContent(
    useStaticQuery(
      graphql`
        {
          markdownRemark(fileAbsolutePath: { regex: "/apartments-intro.md/" }) {
            frontmatter {
              title
            }
            html
          }
        }
      `
    )
  );
  return (
    <Section id="apartments" className="p-0 md:pt-0 md:pb-0">
      <header
        style={bgImage(Background)}
        className="px-6 pb-12 pt-12 md:px-0 md:pt-48 lg:pt-96 xl:pb-80"
      >
        <div className="container mx-auto pt-24 md:px-12 2xl:px-0 text-white">
          <Title className="md:text-6xl lg:text-8xl w-1/2 text-inherit">
            {h.title}
          </Title>
        </div>
      </header>

      <div className="max-w-5xl mx-auto pt-24 px-6 md:px-12">
        <Content content={html} />
      </div>
    </Section>
  );
};

export default AptIntro;
