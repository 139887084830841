import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMdContent } from '../../utils/globals';
import Section from '../templates/Section';
import Background from '../../images/external/garden_children.jpg';
import Title from '../atoms/Title';
import Content from '../atoms/Content';
import Titletag from '../atoms/Titletag';
import { bgImage } from '../../utils/styles';

const Park = () => {
  const { h, html } = useMdContent(
    useStaticQuery(graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/park.md/" }) {
          frontmatter {
            title
            headline
          }
          html
        }
      }
    `)
  );

  return (
    <Section
      id="park"
      className="text-white lg:py-72 overflow-x-hidden"
      style={bgImage(Background, { from: 'rgba(0,0,0, .4)' })}
    >
      <div className="container md:grid md:grid-cols-12 mx-auto">
        <div className="md:col-span-8 md:col-start-2 lg:col-start-2 lg:col-span-8 xl:col-start-2 xl:col-span-7 2xl:col-start-2 2xl:col-span-6 3xl:col-span-6">
          <Titletag className="uppercase">{h.headline}</Titletag>
          <Title className="text-inherit lg:text-8xl">{h.title}</Title>
          <Content content={html} className="md:text-xl md:leading-relaxed" />
        </div>
      </div>
    </Section>
  );
};

export default Park;
