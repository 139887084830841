import React from 'react';
import Section from '../../templates/Section';
import Background from '../../../images/internal/living_parquet.jpg';
import Title from '../../atoms/Title';
import { bgImage } from '../../../utils/styles';
import section from '../../../data/apartments/apartments-details.json';
import Headline from '../../atoms/Headline';
import { motion } from 'framer-motion';
import { fadeLeft, fadeRight } from '../../../utils/animations';
import Living from '../../../images/internal/living2.jpg';
import Kitchen from '../../../images/internal/kitchen.jpg';

const AptDetails = () => {
  const images = [Living, Kitchen];

  return (
    <Section id="apartments" className="p-0 md:pt-0 overflow-x-hidden">
      <header style={bgImage(Background)} className="px-6 md:px-0 3xl:pb-96">
        <div className="container mx-auto pt-24 pb-52 lg:pb-96 md:px-12 text-white">
          <Title className="md:text-6xl lg:text-7xl text-inherit">
            {section.title}
          </Title>
        </div>
      </header>

      <div className="flex flex-col space-y-16 mt-16">
        {section.content.map(({ title, description }, i) => (
          <section
            key={i}
            className="flex flex-col md:flex-row items-center md:even:flex-row-reverse"
          >
            <motion.div
              variants={i % 2 === 0 ? fadeLeft : fadeRight}
              initial="hidden"
              whileInView="show"
              className="p-8 md:p-16 xl:p-24 xl:w-6/12"
            >
              <Headline className="mb-8">{title}</Headline>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </motion.div>

            <motion.div
              variants={i % 2 === 0 ? fadeRight : fadeLeft}
              initial="hidden"
              whileInView="show"
            >
              <img src={images[i]} alt={title} className="px-8 md:px-0" />
            </motion.div>
          </section>
        ))}
      </div>
    </Section>
  );
};

export default AptDetails;
