import React from 'react';

const Field = ({ color, label, ...props }) => {
  const inputClasses = `block w-full bg-transparent border border-${color} border-opacity-60 focus:border-opacity-100 p-4 pt-9 disabled:opacity-75 disabled:cursor-not-allowed`;

  return (
    <label className="block relative">
      <span
        className={`absolute inline-block mb-2 px-4 pt-4 text-sm text-${color} font-bold opacity-70`}
      >
        {label} {props.required && '*'}
      </span>

      {props.type === 'textarea' ? (
        <textarea className={inputClasses} {...props} />
      ) : (
        <input className={inputClasses} {...props} />
      )}
    </label>
  );
};

export default Field;
